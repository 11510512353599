<template>
  <div class="content_container sign_up_email">
    <h1>회원가입</h1>
    <div class="con_email">
      <!--part_01 이메일 비밀번호 시작-->
      <div class="part_01">
        <div class="fill">
          <label for="username">이메일 <span>*</span></label>
          <input
            type="text"
            id="username"
            placeholder="name@ismartorder.co.kr"
            v-model="email"
            @keyup.enter="emailEnter"
          />
        </div>
        <div class="fill password">
          <label for="pass">비밀번호 <span>*</span></label>
          <input
            type="password"
            id="pass"
            minlength="8"
            required
            placeholder="소문자+대문자+숫자+특수문자 조합 8글자 이상"
            v-model="password"
            @keyup.enter="passEnter"
          />
          <div class="passShow" @click="togglePassShow($event)">
            <i class="far fa-eye"></i>
            <i class="fas fa-eye-slash on"></i>
          </div>
        </div>
        <div class="fill password">
          <label for="pass_check">비밀번호 확인 <span>*</span></label>
          <input
            type="password"
            id="pass_check"
            minlength="8"
            required
            placeholder="비밀번호를 한번 더 입력해주세요"
            v-model="passwordRe"
          />
          <div class="passShow" @click="togglePassShow($event)">
            <i class="far fa-eye"></i>
            <i class="fas fa-eye-slash on"></i>
          </div>
        </div>
      </div>
      <!--part_01 이메일 비밀번호 끝-->
      <div class="line"></div>
      <!--part_02 이용약관 동의 시작-->
      <div class="part_02">
        <div class="agree">
          <label>
            <input type="checkbox" name="all" @change="checkAll($event)" />
            전체 이용약관에 동의합니다.
          </label>
        </div>
        <div class="agree">
          <label>
            <input type="checkbox" name="agreement" />
            [필수] 이용약관 동의
          </label>
          <span><router-link to="/policy/term">전체보기</router-link></span>
        </div>
        <div class="agree">
          <label>
            <input type="checkbox" name="agreement" />
            [필수] 개인정보 수집 및 이용 동의
          </label>
          <span><router-link to="/policy/privacy">전체보기</router-link></span>
        </div>
        <div class="agree">
          <label>
            <input type="checkbox" name="agreement" />
            [필수] 만 14세 이상입니다.
          </label>
        </div>
        <div class="agree">
          <label>
            <input type="checkbox" v-model="marketing_yn" />
            [선택] 정보 이메일 / 문자 수신 동의
          </label>
        </div>
      </div>
      <!--part_02 이용약관 동의 끝-->
    </div>
    <button type="button" class="common_button" @click="signup">
      가입하기
    </button>
  </div>
  <SubFooter />
</template>

<script>
import SubFooter from "@/components/SubFooter";
import { postData } from "@/utils/postData";

export default {
  data() {
    return {
      email: "",
      password: "",
      passwordRe: "",
      passValid: false,
      marketing_yn: false,
    };
  },
  components: {
    SubFooter,
  },
  mixins: [postData],
  watch: {
    // 비밀번호 형식 확인(숫자1이상, 소문자1이상, 대문자1이상, 특수문자1이상, 최소 8글자)
    password(newPW) {
      const lower = /[a-z]/;
      const upper = /[A-Z]/;
      const number = /[0-9]/;
      const special = /[`~!@#$%^&*()_|+\-=?;:'",.<>]/;
      if (
        newPW.length >= 8 &&
        newPW.match(lower) &&
        newPW.match(upper) &&
        newPW.match(number) &&
        newPW.match(special)
      ) {
        this.passValid = true;
      } else {
        this.passValid = false;
      }
    },
  },
  methods: {
    // 이메일 입력창에서 엔터시
    emailEnter() {
      document.getElementById("pass").focus();
    },
    passEnter() {
      document.getElementById("pass_check").focus();
    },
    // 비밀번호 '보기/가리기' 토글
    togglePassShow(event) {
      const target = event.currentTarget;
      if (target.previousElementSibling.type == "text") {
        target.previousElementSibling.type = "password";
      } else {
        target.previousElementSibling.type = "text";
      }
      target.children[0].classList.toggle("on");
      target.children[1].classList.toggle("on");
    },
    // 이용약관 전체 체크
    checkAll(event) {
      const agreements = document.querySelectorAll('input[name="agreement"]');
      if (event.currentTarget.checked) {
        agreements.forEach((el) => (el.checked = true));
        this.marketing_yn = true;
      } else {
        agreements.forEach((el) => (el.checked = false));
        this.marketing_yn = false;
      }
    },
    // 회원가입
    signup() {
      const agreements = document.querySelectorAll('input[name="agreement"]');
      let requiredAgree = true;
      agreements.forEach((el) => {
        if (el.checked != true) {
          requiredAgree = false;
        }
      });

      // 필수 입력정보 체크
      if (this.email == "" || this.password == "") {
        this.$store.commit("alert", "이메일과 비밀번호를 입력해주세요");
        document.getElementById("username").focus();
      } else if (!this.passValid) {
        this.$store.commit("alert", "비밀번호 형식을 확인해주세요");
        document.getElementById("pass").focus();
      } else if (this.passwordRe != this.password) {
        this.$store.commit("alert", "비밀번호 확인을 다시해주세요");
        document.getElementById("pass_check").focus();
      } else if (!requiredAgree) {
        this.$store.commit("alert", "필수 이용약관을 모두 체크해주세요");
      } else {
        this.postData(
          "/signup",
          {
            email: this.email,
            password: this.password,
            marketing_yn: this.marketing_yn,
          },
          (data) => {
            this.$store.commit("user/signin", data.user);
            this.$store.commit("signupPop");
          }
        );
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/layout_login.css">
</style>